import React from "react"
import Layout from "../../../../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { buildBlocks } from "../../../../services/builder"
import { parse, serialize } from "parse5"
import { parse5Adapter } from "css-select-parse5-adapter"
import cssSelect from "css-select"

function convertBlock(block, order) {
  const name = block.attrs && block.attrs.name ? block.attrs.name : block.name
  let convertedBlock = {
    attributes: {
      className: "",
    },
    innerBlocks: [],
    name: name,
    order: order,
  }
  if (block.attrs && block.attrs.data) {
    convertedBlock[convertName(name)] = block.attrs.data
  }
  if (block.innerHTML) {
    convertedBlock["attributes"]["content"] = block.innerHTML
  } else if (block.saveContent) {
    convertedBlock["attributes"]["content"] = block.saveContent
  }
  switch (name) {
    case "acf/post-wrap":
      convertedBlock["blockPostWrap"] = {
        backgroundColour: block?.attrs?.data?.background_colour
      }
      break
    case "core/table":
      // Tables need some adjustments to display properly
      let html = parse(block.saveContent)
      let head = cssSelect('thead', html, { adapter: parse5Adapter })
      let headCells = [];
      if (head) {
        headCells.push({
          cells: cssSelect("th", head, { adapter: parse5Adapter }).map(th => {
            let cell = {
              align: "",
              content: th.text,
            }
            if (th.attributes.colspan) {
              cell.attributes = {
                colspan: th.attributes.colspan,
              }
            }
            return cell
          }),
        })
      }
      let body = cssSelect("tbody", html, { adapter: parse5Adapter })
      let bodyCells = []
      let bodyRows = cssSelect(">tr", body, { adapter: parse5Adapter })
      bodyRows.map(row => {
        let cells = cssSelect(">td", row, { adapter: parse5Adapter })
        bodyCells.push({
          cells: cells.map(td => {
            let nestedTable = cssSelect(">table", td, {
              adapter: parse5Adapter,
            })
            return {
              align: "",
              content: nestedTable.length
                ? "<table>" + serialize(nestedTable[0]) + "</table>"
                : getNodeText(td),
            }
          }),
        })
        return cells
      })
      convertedBlock["attributes"]["head"] = headCells
      convertedBlock["attributes"]["body"] = bodyCells
      break
  }
  if (block.innerBlocks) {
    block.innerBlocks.forEach((innerBlock, index) => {
      convertedBlock.innerBlocks.push(convertBlock(innerBlock, index))
    })
  }
  return convertedBlock
}

function getNodeText(node) {
  if (node.nodeName === "#text") {
    return node.value
  }
  if (!node.childNodes) {
    return ""
  }
  return node.childNodes.map((child, index) => getNodeText(child)).join(" ")
}

/**
 * Converts the block name in to a component name
 * eg. core/banner = CoreBannerBlock
 * @param {String} name
 */
function convertName(name) {
  const split = name.split("/")
  const parts = []
  parts.push("block")
  const split2 = split[1].split("-")
  split2.map(part => {
    return parts.push(UCFirst(part))
  })
  return parts.join("")
}

function UCFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export default function Page(props) {
  if (!props || !props.params?.slug || !props?.location?.state?.data?.date) {
    if (typeof window !== `undefined`) {
      window.location = "/investors/reports-results/?tag=rns"
      return
    }
    return
  }
  const pageContext = {
    backgroundColor: "background",
    date: props.location?.state?.data?.date,
    excerpt: "",
    featuredImage: "",
    staticImage: (
      <StaticImage src="../../../../images/news-default.png" alt="" />
    ),
    title: props.location?.state?.data?.title,
    newsDocuments: [],
  }

  let converted = convertBlock(props.location?.state?.data?.content[0], 0)

  const blocks = buildBlocks(
    converted,
    {},
    pageContext,
    props?.location?.state?.data?.id
  )

  return (
    <Layout path={props.uri} title={pageContext.title}>
      {blocks}
    </Layout>
  )
}

import React from "react"
import { Router } from "@reach/router"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PubMap from "../components/PubMap"
import { buildBlocks } from "../services/builder"
import Page from "./investors/reports-results/latest/[slug].jsx"

export const query = graphql`
  query {
    wpPage(uri: { eq: "/find-a-pub/" }) {
      id
      slug
      title
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
    }
  }
`

const FindAPub = ({ data, location }) => {
  const blocks = buildBlocks(data.wpPage.blocks, {}, {}, data.wpPage.id)
  return (
    <Layout meta={data.wpPage.Meta}>
      {blocks}
      <PubMap state={location?.state} />

      {/* No particular reason this is here specifically, it just needs to be somewhere in a used template */}
      <Router basepath="/investors/reports-results/latest">
        <Page path="/:slug" />
      </Router>
    </Layout>
  )
}

export default FindAPub
